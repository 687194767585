import React from 'react'
import styled from "@emotion/styled";
import { Grid, GridColumn } from "semantic-ui-react";
import mq from '@styles/mq'
import { KenticoImage } from '@components/elements/index';

export const PlateDesignImage = styled(({...others})=><KenticoImage {...others} />)`
    position: relative;
    width: 100%;
    display: block;
    ${mq({
        borderTopLeftRadius: ['12px','12px','12px'],
        borderTopRightRadius: ['12px','0px','0px'],
        borderBottomLeftRadius: ['0px','12px','12px'],
        borderBottomRightRadius: ['12px','0px','0px'],
        maxHeight: ['320px', '470px', '470px' ]
    })}
    overflow:hidden;
`


export const PlateDesignContainer = styled(({...others})=><div {...others} />)`
    position: relative;    
    width: 100%;
`

export const PlateDesignImageContentContainer = styled.div`
    position: absolute;
    text-align: left;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition:opacity 0.5s;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;

    ${
        mq({
            opacity: ['1','1','0'],
            padding: ['30px','30px','40px','40px']
        })
    }

    &:hover {
        opacity: 1;
    }
`

export const PlateDesignDataContainer =  styled(({isMobile, ...rest})=><div {...rest} />)`
    &&& {
        text-align: left;
        z-index: 100;
        bottom: 7%;
        width: 100%;
        color: #000;
        height:100%;
        background-color: #eee;
        ${mq({
            marginTop: ['-28px','0px','0px'],
            maxHeight:['none', '470px', '470px'],
            borderTopLeftRadius: ['0px','0px','0px'],
            borderTopRightRadius: ['0px','12px','12px'],
            borderBottomLeftRadius: ['12px','0px','0px'],
            borderBottomRightRadius: ['12px','12px','12px']
        })}
        h1 {
            font-size: ${props => props.isMobile? '26px' : '36px;'};
            font-weight: 900;
            margin: 0px 8px 8px 9px;
        }

        label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #707070;
            ${mq({
                paddingLeft: ['16px','30px','40px']
            })}
            margin-right: 5px;
        }

        span {
            ${mq({
                fontSize: ['18px','20px','20px']
            })}
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color:#3DAE2B;
        }

        a {
            background-color: #eee;
            border: none;
            border-radius: 12px;
            color: #000;
            ${mq({
                padding: ['0px 16px 24px 16px;','4px 26px 24px 20px;','4px 26px 24px 30px;']
            })}
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-weight: bold;
        }
        .feature-divider{
             ${mq({
                marginLeft: ['16px !important;','30px !important;','40px !important;'],
                marginRight: ['16px !important;','30px !important;','40px !important;'],
                paddingBottom: ['0px !important;', '6px !important;','8px !important;']
            })}           
            inner-height: 1px !important;            
        }
        .feature-divider2{
            ${mq({
                marginLeft: ['16px !important;','30px !important;','40px !important;'],
                marginRight: ['16px !important;','30px !important;','40px !important;']                
            })}            
            inner-height: 1px !important;                  
        }
    }
`

export const GridColumnFull = styled(GridColumn)`
    &&&{
        position: relative;
        ${mq({
            padding: ['32px !important;','16px 32px !important;','16px 32px !important;'],         
        })} 
        max-width: 1048px;          
    }
`

export const PriceBlock = styled.div`
    &&&{
        display: inline-flex;
    }
    
`

export const PlateDesignImagePrice = styled.div`
    > h6 {
        display: inline-block;
        margin-left: 5px !important;
        vertical-align: top;
        font-size: 16px !important;
        line-height: 22px !important;
    }
    > span {
        color: white;
        font-size: 12px;
        line-height: 19px;
        font-weight: 100;
        vertical-align: top;
    }
    margin-bottom: 15px;
`

export const Body = styled.div`
    background: #000;
`

export const PlateDesignHeader = styled.div`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 16px 0px 12px 16px;
    ${mq({
        padding: ['16px 0px 12px 16px;','16px 30px 16px 30px;','16px 40px 16px 40px;']
    })};
    margin-right:12px;
`

export const LeftColumn = styled(Grid.Column)`
    padding-right: 0px !important;
`
export const RightColumn = styled(Grid.Column)`
    margin-top: 16px !important;
    padding: 0px 24px 0px 0px !important;
`
export const ContainerRow = styled(Grid.Row)`
    padding: 0px 30px 12px 30px !important;
    ${mq({
        padding: ['0px 30px 12px 30px !important;','0px 30px 5px 30px !important;','0px 30px 5px 41px !important;']
    })}
`
export const ContainerRowNoBottom = styled(Grid.Row)`
    padding-bottom: 0px !important;
`
export const Subtitle = styled.div`
    color: #707070;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom:5px;
`
export const LeftColumnWrapper = styled(Grid.Column)`
 &&&{
 ${mq({
        paddingBottom: ['1px !important','',''],
        paddingRight:['','0px !important','0px !important'],
        paddingTop:['0px !important','0px !important','0px !important'],
    })} 
}
`
export const RightColumnWrapper = styled(GridColumn)`
 &&&{
 ${mq({
    paddingTop: ['0px !important','',''],
    paddingLeft: ['','0px !important','0px !important'],
})}
}
`
export const TopText = styled.div`
    padding: 40px 0px 0px 41px;
    ${mq({
        padding: ['40px 0px 0px 41px','20px 0px 0px 30px','40px 0px 0px 41px'],
    })}
    font-size: 14px;
    font-weight:500;
    color:#707070;
`
export const ImageWrapper = styled.img`
    ${mq({
        marginLeft:['40px','30px','40px'],
        marginBottom:['0px','20px','0px']
    })}
    max-width: 240px;
    max-height: 60px;
`
export const FeatureProductRow = styled(Grid.Row)`
    ${mq({
        marginTop:['0px','20px','36px'],
    })}
`