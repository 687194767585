import { HomepageItemProps } from "@dataModels/Homepage/Homepage.models";

export const mapToHomepageItem = (data: any): HomepageItemProps => {
    return {
        desktop_image_url: data.desktopImage.value[0].url,
        mobile_image_url: data.mobileImage.value[0].url,
        tablet_image_url: data.tabletImage.value[0].url,
        new_plate_link: data.btnNewPlateLink.value,
        new_plate_title: data.btnNewPlateTitle.value,
        new_plate_description: data.btnNewPlateDescription.value,
        redesign_plate_link: data.btnRedesignPlateLink.value,
        redesign_plate_title: data.btnRedesignPlateTitle.value,
        redesign_plate_description: data.btnRedesignPlateDescription.value,
        description: data.description.value,
        newTab: data.new.value,
        mobileTextAlignment: data.mobileTextAlign.value,
        createPlateUniqueGTMID: data.createPlateGtmUniqueId.value,
        redesignPlateUniqueGTMID: data.redesignPlateGtmUniqueId.value,
        giftingText: data.giftingText.value,
        giftingLink: data.giftingLink.value,
        giftingGTMID: data.giftingLinkGtmId.value,
        homepageImageLink: data.homepageImageLink.value
    }
}

