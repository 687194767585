import { PlateDesignImageGridItem, PlateDesignImageGridProps } from "~/dataModels/PlateDesignImageGrid/PlateDesignImageGrid.models"
import { mapToButton } from "@utils/mapper"

export const mapToPlateDesignImageGrid = (data: any): PlateDesignImageGridProps => {
    return {
        designs: data.plateDesigns.linkedItems.map((plateDesign: any) => mapToPlateDesignImageGridItem(plateDesign)),
        heading: data.heading.value,
        subHeading: data.subHeading.value
    }
}

const mapToPlateDesignImageGridItem = (data: any): PlateDesignImageGridItem => {
    return {
        backgroundImage: {
            description: data.elements.backgroundImage.value[0].description,
            url: data.elements.backgroundImage.value[0].url
        },
        button: mapToButton(data.elements),
        heading: data.elements.title.value,
        price: data.elements.price.value,
        hideOnMobile: data.elements.hideOnMobile.value.length > 0
    }

}