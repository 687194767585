import {product} from './Types'


export const mapDataToModel = (data: any) =>{
    var newProducts: product[] = []
    data.product.linkedItems.map ((item: any) => {
        var product: product  = {
            description: item.elements.description.value,
            new_from_label: item.elements.newFromLabel.value,
            desktop_image_url: item.elements.image.value[0].url,
            mobile_image_url: item.elements.mobileImage.value[0].url,
            new_from_price: item.elements.newFromPrice.value,
            order: item.elements.order.value,
            restyle_from_label: item.elements.restyleFromLabel.value,
            resytle_from_price: item.elements.resytleFromPrice.value,
            view_more_label: item.elements.viewMoreLabel.value,
            view_more_url: item.elements.viewMoreUrl.value,
            gtm_unique_id: item.elements.gtmUniqueId.value,
            subtitle: item.elements.subtitle.value,
            plate_image_url: item.elements.plateImageUrl.value,
            create_button_text: item.elements.createButtonText.value,
            create_button_url: item.elements.createButtonUrl.value,
        }
        newProducts.push(product)
    })
    return newProducts
}