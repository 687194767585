import { LoaderFunction } from '@remix-run/node';
import { data, useFetcher, useLoaderData } from '@remix-run/react';
import SEO from '@components/SEO/SEO';
import { queryItemByCodeName } from '~/utils/kontentClientWrapper';
import { mapToCtaImageCards } from '~/components/CallToActionCardList/mapper';
import { mapToPlateDesignImageGrid } from '~/mappers/PlateDesignImageGrid/PlateDesignImageGrid.mapper';
import { mapToPlateStorySliderItems } from '~/mappers/PlateStorySlider/PlateStorySlider.mapper';
import { mapToHowItWorksItems } from '~/mappers/HowItWorks/howItWorks.mapper';
import { mapToInfoBannerContainer } from '~/components/ImageInformationList/mapper';
import { mapToImageLinkBanner } from '~/components/ImageButtonBanner/mapper';
import { mapToKiwiVIPVisible } from '~/components/SignupBanner/mapper';
import { mapToCustomerReviewData } from '~/components/CustomerReviewCardSlider/mapper';
import { mapToHomepageItem } from '~/mappers/Homepage/Homapage.mapper';
import HomepageBlack from '~/components/HomepageBlack/HomepageBlack';
import theme from '~/styles/theme';
import { mapToPlateDesignList } from '~/mappers/ProductModels/mapper';
import GenericBannerContainer from '~/components/Mobile/GenericBannerContainer/GenericBannerContainer';
import PlateDesignSlider from '@components/HomepagePopularPlatesCarousel/PlateDesignSlider';
import CompHowItWorks from '~/components/HowItWorks/CompHowItWorks';
import React, { useEffect, useState } from 'react';
import ImageButtonBanner from '~/components/ImageButtonBanner/ImageButtonBanner';
import SignupBannerWrapper from '~/components/SignupBanner/SignupBannerWrapper';
import CustomerReviewCardSlider from '~/components/CustomerReviewCardSlider/CustomerReviewCardSlider';
import { mapToSEO } from '~/components/SEO/mapper';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import FeatureProductImageGrid from '~/components/FeatureProductImageGrid/FeatureProductImageGrid';
import { mapDataToModel } from '~/components/HomepageBlack/mapper';
import { HomepageV2, KiwiplatesHomepage } from '~/_models';
import { useClientEnv } from '~/contexts/ClientEnvContext';
export { headers } from '~/defaults.server';
type LoaderProps = {
  homepageData: KiwiplatesHomepage;
  homepageV2Data: HomepageV2;
};

export const loader: LoaderFunction = async ({ request }) => {
  const homepageV2Data = await queryItemByCodeName<HomepageV2>(
    'homepage_v2',
    2,
  );
  const homepageData = await queryItemByCodeName<KiwiplatesHomepage>(
    'home_page',
    4,
    ['dual_size_plate'],
  );

  return data<LoaderProps>(
    { homepageData, homepageV2Data },
    {
      headers: {
        'Cache-Control': `public, max-age=${process.env.HTTP_CACHE_MAX_AGE || 300}, stale-while-revalidate=${process.env.STALE_WHILE_REVALIDATE || 3600}`,
      },
    },
  );
};

const Homepage = () => {
  const { homepageData, homepageV2Data } = useLoaderData<LoaderProps>();
  const data = useClientEnv();

  const components = homepageData.elements.components;
  if (components === null || components === undefined) {
    return null;
  }

  const { elements } = homepageV2Data;
  const {
    ctaCardsWithImages,
    homepageCarousels,
    infoBanner,
    imageLinkBanner,
    featuredPlateDesigns,
    featuredPlateStories,
    howItWorks,
  } = elements;

  const seoData = mapToSEO(elements);
  // const ctaCardData = mapToCtaImageCards(ctaCardsWithImages.linkedItems)
  const plateDesignImageGridData = mapToPlateDesignImageGrid(
    featuredPlateDesigns.linkedItems[0].elements,
  );
  const popularPlateDesignTitle =
    featuredPlateDesigns.linkedItems[0].elements.heading.value;
  const plateStorySliderData = mapToPlateStorySliderItems(
    featuredPlateStories.linkedItems,
  );
  const compHowItWorksData = mapToHowItWorksItems(
    howItWorks.linkedItems[0].elements,
  );

  const imageLinkBannerData =
    imageLinkBanner && imageLinkBanner.linkedItems.length > 0
      ? mapToImageLinkBanner(imageLinkBanner.linkedItems[0].elements)
      : null;

  const kiwiVip = mapToKiwiVIPVisible(elements);
  const customerReviewData = mapToCustomerReviewData(
    elements.customerReviews.linkedItems[0].elements,
    true,
  );

  return (
    <>
      <SEO {...seoData} />
      {components.linkedItems.map((item, index) => {
        switch (item.system.codename) {
          case 'hp_tile___winter_sale':
            const homepageBlackData = mapToHomepageItem(item.elements);
            return <HomepageBlack key={index} data={homepageBlackData} />;
          /*TODO: no kontent content, remove?
            case 'KontentItemCompCreateNewPlate':
              var title = item.elements.title?.value
              var subtitle = item.elements.subtitle?.value
              var vehicleType = item.elements.vehicletype?.value.find(() => true)?.name
              var vehicleTheme = item.elements.vehicletheme?.value.find(() => true)?.name
              const apiUrl = siteMetadata.apiUrl;
              const reCaptchaSiteKey = siteMetadata.reCaptchaSiteKey;
              const reCaptchaSiteKeyV2 = siteMetadata.reCaptchaSiteKeyV2;

              return (
                <GenericBannerContainer
                  padding={{
                    mobile: {
                      top: 28,
                      bottom: 5,
                    },
                    desktop: {
                      top: 20,
                      bottom: 45,
                    },
                  }}
                  backgroundColor="#000"
                >
                  <QuickCombinationFinder
                    title={title}
                    subtitle={subtitle}
                    vehicleType={vehicleType}
                    vehicleTheme = {vehicleTheme}
                    apiUrl= {apiUrl}
                    reCaptchaSiteKey={reCaptchaSiteKey}
                    reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
                  />
                </GenericBannerContainer>
              ) */
          case 'comp___feature_products':
            return (
              <div key={index}>
                <FeatureProductImageGrid
                  apiUrl={data.apiUrl}
                  elements={mapDataToModel(item.elements)}
                />
              </div>
            );
          /*TODO: no kontent content, remove?
            case 'KontentItemCompRestylePlate':
              return (
                <GenericBannerContainer
                  padding={{
                    mobile: {
                      top: 10,
                      bottom: 45,
                    },
                    desktop: {
                      top: 20,
                      bottom: 83,
                    },
                  }}
                  backgroundColor={theme.brand.colors.beige}
                >
                  <CallToActionImageCardList
                    backgroundColor={theme.brand.colors.beige}
                    alternateButtonLayout={false}
                    cards={ctaCardData}
                  />
                </GenericBannerContainer>
              ) 
            case 'KontentItemCompPopularPlateDesigns':
              return (
                <GenericBannerContainer
                  padding={{
                    mobile: {
                      top: 57,
                      bottom: 70,
                    },
                    desktop: {
                      top: 80,
                      bottom: 93,
                    },
                  }}
                  backgroundColor={theme.brand.colors.white}
                >
                  <PlateDesignImageGrid
                    {...plateDesignImageGridData}
                  ></PlateDesignImageGrid>
                </GenericBannerContainer>
              ) */

          case 'comp___carousel':
            const plate_designs = (
              item.elements.content as any
            ).linkedItems.find(
              (item: any) => item.system.codename === 'plate_designs_carousel',
            );
            const plateDesignList = mapToPlateDesignList(
              plate_designs?.elements.plateDesigns.linkedItems,
              'ABC123',
              undefined,
            );

            return (
              <PlateDesignSlider
                key={index}
                plateDesigns={plateDesignList}
                title={popularPlateDesignTitle}
              />
            );

          /* TODO: remove due no content? 
            case 'KontentItemCompPlateStorySlider':
              return (
                <GenericBannerContainer
                  padding={{
                    mobile: {
                      top: 60,
                      bottom: 75,
                    },
                    desktop: {
                      top: 100,
                      bottom: 95,
                    },
                  }}
                  backgroundColor={theme.brand.colors.beige}
                >
                  <PlateStorySlider {...plateStorySliderData}></PlateStorySlider>
                </GenericBannerContainer>
              ) */
          case 'comp___how_it_works':
            return (
              <GenericBannerContainer
                key={index}
                padding={{
                  mobile: {
                    top: 48,
                    bottom: 40,
                  },
                  desktop: {
                    top: 100,
                    bottom: 95,
                  },
                }}
                backgroundColor={theme.brand.colors.black}
              >
                <CompHowItWorks {...compHowItWorksData}></CompHowItWorks>
              </GenericBannerContainer>
            );
          case 'comp___footer':
            return (
              <React.Fragment key={index}>
                {imageLinkBannerData && (
                  <ImageButtonBanner {...imageLinkBannerData} />
                )}
                {customerReviewData && (
                  <CustomerReviewCardSlider {...customerReviewData} />
                )}
                {kiwiVip.visible && (
                  <SignupBannerWrapper version={kiwiVip.version} />
                )}
              </React.Fragment>
            );
          default:
            break;
        }
      })}
    </>
  );
};

export default Homepage;
