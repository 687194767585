import { useNavigate } from "@remix-run/react"

export const useKpNavigate = () => {
    const navigate = useNavigate()

    const kpNavigate = (url: string) => {
      if (url.trim() === '') {
        throw new Error('Navigation url is invalid!')
      }
    
      if (url.startsWith('http')) {
        window.location.href = url;
      } else {
        navigate(url);
      }
    }

    return kpNavigate;
}