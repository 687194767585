import React, { useEffect, useState } from 'react'
import { Grid, Segment, Container } from 'semantic-ui-react'
import { LoadingScreen, Block, HomepageImage, ImageContainer, LinkButton, MobileHompageWrapper, MobileHomepageRow, HomepageRow, ImageRightColumn, HomepageGrid, TabletSegment, DesktopSegment } from './HomepageBlack.styles'
import { HomepageItemProps } from '@dataModels/Homepage/Homepage.models'
import { Sources } from '@components/elements/index'
import { rules } from '@styles/mq'
import theme from '~/styles/theme'
import { useKpNavigate } from '~/hooks/useKpNavigate'

interface Props {
  data: HomepageItemProps
}

const HomepageBlack: React.FC<Props> = ({ data }) => {
  const [width, setWidth] = useState(0)
  const navigate = useKpNavigate()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
      };
  
      handleWindowSizeChange(); // Set initial width
      window.addEventListener('resize', handleWindowSizeChange);
  
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }
  }, []);

  const blackComponent = (isMobile = false, isTablet = false) => {
    const height = (width-28)/1.17
    
    const renderButtons = () => (
      <div className="nav-button">
        <div className="btn1">
          <a id={data.createPlateUniqueGTMID} href={data.new_plate_link}>
            <Grid>
              <Grid.Column width={14}>
                <div className="title">{data.new_plate_title}</div>
                <div className="description">{data.new_plate_description}</div>
              </Grid.Column>
              <Grid.Column width={2}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
              </Grid.Column>
            </Grid>
          </a>
        </div>
        <div className="btn1 btn2">
          <a id={data.redesignPlateUniqueGTMID} href={data.redesign_plate_link}>
            <Grid>
              <Grid.Column width={14}>
                <div className="title">{data.redesign_plate_title}</div>
                <div className="description">{data.redesign_plate_description}</div>
              </Grid.Column>
              <Grid.Column width={2}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
              </Grid.Column>
            </Grid>
          </a>
        </div>
        <LinkButton hasText={data.giftingText ? true: false} isMobile={isMobile}>
          <a id={data.giftingGTMID} href={data.giftingLink}>
            {data.giftingText}
          </a>
        </LinkButton>
      </div>
    )

    const renderImage = (hasPadding = false) => (
      <ImageContainer hasPadding={hasPadding} onClick={() => navigate(data.homepageImageLink)}>
        <HomepageImage
          height={isMobile ? height : undefined}
          className="banner"
          lowSrc={`${isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url)}?w=350&auto=format`}
          sources={Sources(isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url), rules, theme.imageBreakpoints)}
        />
      </ImageContainer>
    )

    if (isMobile) {
      return (
        <Block img={data.mobile_image_url} isMobile={isMobile} mobileTextAlignment={data.mobileTextAlignment}>
          <Grid stackable>
            <HomepageRow columns={2}>
              <Grid.Column mobile={16}>
                <div className="center">
                  {data.newTab?.length ? <div className="new">{data.newTab}</div> : null}
                  {renderButtons()}
                </div>
              </Grid.Column>
              {renderImage()}
            </HomepageRow>
          </Grid>
        </Block>
      )
    }

    if (isTablet) {
      return (
        <TabletSegment>
          <Block img={data.tablet_image_url} isMobile={isMobile} mobileTextAlignment={data.mobileTextAlignment}>
            <HomepageGrid stackable>
              <HomepageRow columns={2}>
                <Grid.Column tablet={width > 1000 ? 5 : (width > 850 ? 6 : 7)}>
                  <div className="center">
                    {data.newTab?.length ? <div className="new">{data.newTab}</div> : null}
                    {renderButtons()}
                  </div>
                </Grid.Column>
                <Grid.Column tablet={width > 1000 ? 11 : (width > 850 ? 10 : 9)}>
                  {renderImage(width > 1000)}
                </Grid.Column>
              </HomepageRow>
            </HomepageGrid>
          </Block>
        </TabletSegment>
      )
    }

    return (
      <DesktopSegment>
        <Container>
          <Block img={data.desktop_image_url} isMobile={isMobile} mobileTextAlignment={data.mobileTextAlignment}>
            <Grid stackable>
              <HomepageRow columns={2}>
                <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={4} widescreen={4}>
                  <div className="center">
                    {data.newTab?.length ? <div className="new">{data.newTab}</div> : null}
                    {renderButtons()}
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={12} widescreen={12}>
                  {renderImage(width >= 1440)}
                </Grid.Column>
              </HomepageRow>
            </Grid>
          </Block>
        </Container>
      </DesktopSegment>
    )
  }

  return (
    <LoadingScreen>
      <div className="block md:hidden">
        {blackComponent(true, false)}
      </div>
      <div className="hidden md:block lg:hidden">
        {blackComponent(false, true)}
      </div>
      <div className="hidden lg:block">
        {blackComponent()}
      </div>
    </LoadingScreen>
  )
}

export default HomepageBlack

