import React, { useRef, useEffect, useState } from "react"
import * as Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { HeaderTitle, SliderContainer } from "./PlateDesignSlider.styles"
import PlateDesignStyleContainer from "@components/HomepagePopularPlatesCarousel/PlateDesignStyleContainer"
import { PlateCategoryMetaInfo } from "~/dataModels/Product/PlateCategoryMetaInfo";
import { ProductModelCategoryList } from "~/dataModels/ProductModels";

interface PlateDesign {
  metaInfo: PlateCategoryMetaInfo, 
  products: ProductModelCategoryList
}

interface PlateDesignSliderProps {
  title: string;
  plateDesigns: PlateDesign[];
}

const PlateDesignSlider: React.FC<PlateDesignSliderProps> = ({ title, plateDesigns }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  let firstClientX: number, clientX: number
  const ReactSlick = Slider.default
  const [isClient, setIsClient] = useState(false)

  const preventTouch = (e: TouchEvent) => {
    const minValue = 5 // threshold
    clientX = e.touches[0].clientX - firstClientX

    if (Math.abs(clientX) > minValue) {
      // Prevent vertical scrolling when swiping horizontally
      e.preventDefault()
    }
  }

  const touchStart = (e: TouchEvent) => {
    firstClientX = e.touches[0].clientX
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 7,
    lazyLoad: 'progressive',
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 2570,
        settings: {
          slidesToShow: 6,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 539,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          infinite: true,
          dots: false,
          arrows: false
        }
      }
    ]
  }

  useEffect(() => {
    setIsClient(true)

    const currentContainerRef = containerRef.current

    if (currentContainerRef) {
      currentContainerRef.addEventListener("touchstart", touchStart)
      currentContainerRef.addEventListener("touchmove", preventTouch, { passive: false })
    }

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("touchstart", touchStart)
        currentContainerRef.removeEventListener("touchmove", preventTouch, { passive: false })
      }
    }
  }, [])

  // Slider relies on client side APIs
  if (!isClient) return null

  return (
    <SliderContainer ref={containerRef}>
      <HeaderTitle>{title}</HeaderTitle>
      <ReactSlick {...settings}>
        {plateDesigns.map((data, index) => (
          <PlateDesignStyleContainer key={index} data={data} />
        ))}
      </ReactSlick>
    </SliderContainer>
  )
}

export default PlateDesignSlider
